import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import AppSpin from "./components/Suspense";

const Signin = lazy(() => import("./pages/sign-in/Signin"));
const Signup = lazy(() => import("./pages/sign-up/Signup"));
const Home = lazy(() => import("./pages/home"));
const ListCandidate = lazy(() => import("./pages/candidate/list"));
const FindCandidate = lazy(() => import("./pages/candidate/find"));
const ListJob = lazy(() => import("./pages/job/list"));
const Calendar = lazy(() => import("./pages/calendar"));
const AccountActivation = lazy(() => import("./pages/account/activation"));

const Routes = () => {
  return (
    <Suspense fallback={<AppSpin />}>
      <Switch>
        <Route exact path="/sign-in" component={Signin} />
        <Route exact path="/sign-up" component={Signup} />
        <Route exact path="/account/activation" component={AccountActivation} />
        <PrivateRoute exact path="/" children={<Home />} />
        <PrivateRoute
          exact
          path="/candidate/list"
          children={<ListCandidate />}
        />
        <PrivateRoute
          exact
          path="/candidate/find"
          children={<FindCandidate />}
        />
        <PrivateRoute exact path="/job/list" children={<ListJob />} />
        <PrivateRoute exact path="/calendar" children={<Calendar />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
