import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import React from "react";
import { useTokenStore } from "../../utils/useTokenStore";
import { MenuInfo } from "rc-menu/lib/interface";

const UserMenu = () => {
  const { setTokens } = useTokenStore();

  const onClick = ({ key }: MenuInfo) => {
    switch (key) {
      case "logout":
        setTokens({ accessToken: "", refreshToken: "" });
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu className="w-36 " onClick={onClick}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        Profile
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        Settings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Avatar size="default" alt="avatar" icon={<UserOutlined />} />
    </Dropdown>
  );
};

export default UserMenu;
