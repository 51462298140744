import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";

const NotificationMenu = () => {
  const onClick = ({ key }: MenuInfo) => {
    switch (key) {
      case "logout":
        break;
      default:
        break;
    }
  };
  const menu = <Menu className="w-36 " onClick={onClick}></Menu>;
  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Button className="h-7 px-2 py-0 border-0 shadow-none">
        <Badge dot>
          <BellOutlined />
        </Badge>
      </Button>
    </Dropdown>
  );
};

export default NotificationMenu;
