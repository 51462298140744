import { Drawer } from "antd";
import { lazy, Suspense } from "react";
import { useDrawerStore } from "../../../utils/useDrawerStore";
import AppSpin from "../../Suspense";

const Main = lazy(() => import("./Main"));

const CreateCandidate = () => {
  const { createCandidate, setCreateCandidate } = useDrawerStore();
  return (
    <Drawer
      width="900"
      title="Create New Candidate"
      onClose={() => setCreateCandidate(false)}
      visible={createCandidate}
    >
      <Suspense fallback={<AppSpin />}>
        <Main />
      </Suspense>
    </Drawer>
  );
};

export default CreateCandidate;
