import { Drawer } from "antd";
import { lazy, Suspense } from "react";
import { useDrawerStore } from "../../utils/useDrawerStore";
import AppSpin from "../Suspense";

const CreateEventComponent = lazy(() => import("./Component"));

const CreateEvent = () => {
  const { event, setEvent } = useDrawerStore();
  return (
    <Drawer
      width="700"
      title="Create New Event"
      onClose={() => setEvent(false)}
      visible={event}
    >
      <Suspense fallback={<AppSpin />}>
        <CreateEventComponent />
      </Suspense>
    </Drawer>
  );
};

export default CreateEvent;
