import { Drawer } from "antd";
import { lazy, Suspense } from "react";
import { useDrawerStore } from "../../utils/useDrawerStore";
import AppSpin from "../Suspense";

const CreateCompanyComponent = lazy(() => import("./Component"));

const CreateCompany = () => {
  const { company, setCompany } = useDrawerStore();
  return (
    <Drawer
      width="900"
      title="Create New Company"
      onClose={() => setCompany(false)}
      visible={company}
    >
      <Suspense fallback={<AppSpin />}>
        <CreateCompanyComponent />
      </Suspense>
    </Drawer>
  );
};

export default CreateCompany;
