import {
  PlusOutlined,
  UserAddOutlined,
  BuildOutlined,
  NotificationOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import React from "react";
import { useDrawerStore } from "../../utils/useDrawerStore";

enum keys {
  candidate = "candidate",
  job = "job",
  company = "company",
  event = "event",
}

const PlusMenu = () => {
  const { setCreateCandidate, setCompany, setEvent, setJob } = useDrawerStore();
  const onMenuClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case keys.candidate:
        setCreateCandidate(true);
        break;
      case keys.job:
        setJob(true);
        break;
      case keys.company:
        setCompany(true);
        break;
      case keys.event:
        setEvent(true);
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item icon={<NotificationOutlined />} key={keys.job}>
        Create Job
      </Menu.Item>
      <Menu.Item icon={<CalendarOutlined />} key={keys.event}>
        Create Event
      </Menu.Item>
      <Menu.Item icon={<BuildOutlined />} key={keys.company}>
        Create Company
      </Menu.Item>
      <Menu.Item icon={<UserAddOutlined />} key={keys.candidate}>
        Create Candidate
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Button
        className="h-7 px-2 py-0"
        icon={<PlusOutlined className="text-blue-500" />}
      >
        Create
      </Button>
    </Dropdown>
  );
};

export default PlusMenu;
