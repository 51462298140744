import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { apiBaseUrl } from "./constants";
import { useTokenStore } from "./useTokenStore";

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { accessToken } = useTokenStore.getState();

  // return the headers to the context so httpLink can read them
  if (!!accessToken) {
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  }

  return headers;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    const message = graphQLErrors.map((e) => e.message).join(", ");
    console.log("Graphql Error!: " + message);
  }
});

const logoutLink = onError(({ networkError }) => {
  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    useTokenStore.setState({ accessToken: "", refreshToken: "" });
  }
});

const httpLink = createHttpLink({
  uri: apiBaseUrl,
  credentials: "include",
});

export const apolloClient = new ApolloClient({
  link: from([logoutLink, errorLink, authLink, httpLink]),
  cache,
});
