import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { apolloClient } from "./utils/apolloClient";

import enUs from "antd/lib/locale/en_US";

import "@ant-design/pro-form/dist/form.css";
import "@ant-design/pro-table/dist/table.css";
import "@ant-design/pro-layout/dist/layout.css";
import "@ant-design/pro-card/dist/card.css";
import "antd/dist/antd.css";

import { lazy, Suspense } from "react";
import Layout from "./Layout";
import { ConfigProvider, Drawer } from "antd";
import CreateCandidate from "./components/candidate/create";
import AppSpin from "./components/Suspense";
import CreateCompany from "./components/company-create";
import CreateEvent from "./components/event-create";
import CreateJob from "./components/job-create";
import { useTokenStore } from "./utils/useTokenStore";
import { useEffect } from "react";
import Routes from "./Routes";
import { useDrawerStore } from "./utils/useDrawerStore";

const ViewCandidate = lazy(() => import("./components/candidate/profile"));

function App() {
  const { accessToken, setTokens } = useTokenStore();
  const { candidate, setCandidate } = useDrawerStore();

  useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode<{ exp: number }>(accessToken);
      if (decoded.exp && decoded.exp * 1000 < Date.now()) {
        setTokens({ accessToken: "", refreshToken: "" });
      }
    }
  }, [accessToken, setTokens]);

  return (
    <BrowserRouter>
      <Suspense fallback={<AppSpin />}>
        <ApolloProvider client={apolloClient}>
          <ConfigProvider locale={enUs}>
            <Layout>
              <Routes />
              <Drawer
                width="900"
                onClose={() => setCandidate(false)}
                visible={candidate}
              >
                <Suspense fallback={<AppSpin />}>
                  <ViewCandidate />
                </Suspense>
              </Drawer>
            </Layout>
            <CreateCandidate />
            <CreateCompany />
            <CreateEvent />
            <CreateJob />
          </ConfigProvider>
        </ApolloProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
