import {
  BuildOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  NotificationOutlined,
  ProjectOutlined,
  UserOutlined,
} from "@ant-design/icons";

const defaultProps = {
  route: {
    path: "/",
    routes: [
      {
        path: "/",
        name: "Dashboard",

        icon: <ProjectOutlined />,
      },
      {
        path: "/candidate/find",
        name: "Find Candidates",
        icon: <FileSearchOutlined />,
      },
      {
        path: "/candidate/list",
        name: "Candidates",
        icon: <UserOutlined />,
      },
      {
        path: "/company/list",
        name: "Companies",
        icon: <BuildOutlined />,
      },
      {
        path: "/calendar",
        name: "Calender",
        icon: <CalendarOutlined />,
      },
      {
        path: "/job/list",
        name: "Jobs",
        icon: <NotificationOutlined />,
      },
    ],
  },
  location: {
    pathname: "/",
  },
};

export default defaultProps;
