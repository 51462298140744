import { ReactNodeArray } from "react";
import ProLayout from "@ant-design/pro-layout";
import { Link, useLocation } from "react-router-dom";
import _defaultProps from "./_defaultProps";
import TheNavbar from "./components/the-navbar";
import { useTokenStore } from "./utils/useTokenStore";
import useAppDrawers from "./components/useAppDrawers";

type Props = {
  children: ReactNodeArray;
};

const Layout = ({ children }: Props) => {
  const { pathname } = useLocation();
  const isAuth = !!useTokenStore().accessToken;
  useAppDrawers();

  return (
    <ProLayout
      {..._defaultProps}
      location={{ pathname }}
      title="MA CRM"
      logo="/favicon.ico"
      navTheme="light"
      className="h-screen"
      rightContentRender={() => <TheNavbar />}
      menuItemRender={(item, dom) => {
        return <Link to={item.path || "/"}>{dom}</Link>;
      }}
      fixSiderbar
      {...(!isAuth ? { menuRender: false } : {})}
    >
      {children}
    </ProLayout>
  );
};

export default Layout;
