import { Button } from "antd";

import { useHistory } from "react-router-dom";

import { useTokenStore } from "../../utils/useTokenStore";
import NotificationMenu from "./NotificationMenu";
import PlusMenu from "./PlusMenu";
import UserMenu from "./UserMenu";

const TheNavbar = () => {
  const { push } = useHistory();
  const { accessToken } = useTokenStore();
  const hasTokens = !!accessToken;

  return (
    <>
      {!hasTokens ? (
        <div className="flex items-center gap-4 h-full">
          <Button onClick={() => push("/sign-in")}>Sign In</Button>
          <Button onClick={() => push("/sign-up")} type="primary">
            Sign Up
          </Button>
        </div>
      ) : (
        <div className="flex items-center gap-4 h-full flex-row-reverse">
          <UserMenu />
          <PlusMenu />
          <NotificationMenu />
        </div>
      )}
    </>
  );
};

export default TheNavbar;
