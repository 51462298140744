import { Drawer } from "antd";
import { lazy, Suspense } from "react";
import { useDrawerStore } from "../../utils/useDrawerStore";
import AppSpin from "../Suspense";

const CreateJobComponent = lazy(() => import("./Component"));

const CreateJob = () => {
  const { job, setJob } = useDrawerStore();
  return (
    <Drawer
      width="900"
      title="Create New Job"
      onClose={() => setJob(false)}
      visible={job}
    >
      <Suspense fallback={<AppSpin />}>
        <CreateJobComponent />
      </Suspense>
    </Drawer>
  );
};

export default CreateJob;
