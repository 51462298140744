import { useEffect } from "react";
import { useLocation } from "react-router";
import { useDrawerStore } from "../utils/useDrawerStore";
import queryString from "query-string";
import { drawerEnum } from "./drawer.enum";

const useAppDrawers = () => {
  const { setCandidate, setCompany, setCreateCandidate, setEvent, setJob } =
    useDrawerStore();
  const { search } = useLocation();
  useEffect(() => {
    const parsed = queryString.parse(search);
    if (parsed.drawer) {
      switch (parsed.drawer) {
        case drawerEnum.candidate:
          setCandidate(true);
          break;

        default:
          setCandidate(false);
          setCompany(false);
          setCreateCandidate(false);
          setEvent(false);
          setJob(false);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
};

export default useAppDrawers;
