import { combine } from "zustand/middleware";
import create from "zustand";

export const useDrawerStore = create(
  combine(
    {
      candidate: false,
      company: false,
      job: false,
      event: false,
      createCandidate: false,
    },
    (set) => ({
      setCandidate: (candidate: boolean) => {
        set({ candidate });
      },
      setCreateCandidate: (createCandidate: boolean) => {
        set({ createCandidate });
      },
      setCompany: (company: boolean) => {
        set({ company });
      },
      setJob: (job: boolean) => {
        set({ job });
      },
      setEvent: (event: boolean) => {
        set({ event });
      },
    })
  )
);
